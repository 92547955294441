 <template>
  <div class="footer_wrap">

  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>


<style lang="scss" scoped>
</style>
